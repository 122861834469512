import React from "react"

import { FooterContainer, FooterMainColumn } from "./style"
import AvenueLink from "components/AvenueLink"

const Footer = () => (
  <FooterContainer>
    <FooterMainColumn>
      <AvenueLink to="/about-finefixer">About FineFixer</AvenueLink>
      <AvenueLink to="/terms-of-use-privacy">Terms of use and privacy</AvenueLink>
    </FooterMainColumn>
  </FooterContainer>
)
export default Footer
