import React, { useState, useContext, useEffect } from "react"
import { ProgressBarContainer } from "./style"
import Marker from "./Marker"
import { FlowContext } from "../../context/FlowContext"
import usePrevious from "hooks/usePrevious.js"

const ProgressBar = () => {
  const [currentPosition, setCurrentPosition] = useState(0)
  const {
    currentScreen,
    forwards,
    screensData,
    currentScreenIndex,
  } = useContext(FlowContext)
  // console.log("forwards:", forwards)
  // console.log("screensData:", screensData)

  const maxBranchLength = 1
  const previousScreen = usePrevious(currentScreen) || null

  // console.log("currentScreenIndex:", currentScreenIndex)

  const branchScreens = screensData.filter(
    screen => screen.branchSettings.branchQuestion
  )
  // console.log("branchScreens:", branchScreens)

  var branchGroups = {}
  for (var i = 0; i < branchScreens.length; i++) {
    branchGroups[
      branchScreens[i].branchSettings.branchQuestion.branchGroup.name.title
    ] =
      1 +
      (branchGroups[
        branchScreens[i].branchSettings.branchQuestion.branchGroup.name.title
      ] || 0)
  }

  const numberOfBranchGroups = Object.keys(branchGroups).length

  const totalLength =
    screensData.length -
    branchScreens.length 
  //  + ( maxBranchLength * numberOfBranchGroups)

  
  // console.log("totalLength:", totalLength)
  // console.log('screensData:', screensData)
  // console.log('branchScreens:', branchScreens)

  const fineScreens = screensData.filter(
    screen => screen.details.step === "step1"
  )
  // console.log("fineScreens:", fineScreens)

  const detailsScreens = screensData.filter(
    screen => screen.details.step === "step2"
  )
  // console.log("detailsScreens:", detailsScreens)

  const detailsPosition =
    ((fineScreens.length -
      branchScreens.length 
      // + (maxBranchLength * numberOfBranchGroups)
      ) /
      totalLength) *
    100
  // console.log("detailsPosition:", detailsPosition)

  useEffect(() => {
    // if (
    //   currentScreenIndex ===
    //     fineScreens.length - branchScreens.length - maxBranchLength - 2 &&
    //   !forwards
    // ) {
    //   setCurrentPosition((currentScreenIndex / totalLength) * 100)
    //   //branch forwards
    // } else

    //branch forwards
    if (currentScreen.branchSettings.branchQuestion && forwards) {
      const thisBranchLength = screensData.filter(
        question =>
          question.branchSettings.branchQuestion &&
          question.branchSettings.branchQuestion.title ===
            currentScreen.branchSettings.branchQuestion.title
      ).length

      setCurrentPosition(
        currentPosition +
          (maxBranchLength / (thisBranchLength + 1) / totalLength) * 100
      )
      //branch forwards (lastOneOfBranch)
    } else if (
      previousScreen &&
      previousScreen.branchSettings.branchQuestion &&
      forwards
    ) {

      const thisBranchLength = screensData.filter(
        question =>
          question.branchSettings.branchQuestion &&
          question.branchSettings.branchQuestion.title ===
            previousScreen.branchSettings.branchQuestion.title
      ).length

      setCurrentPosition(
        currentPosition +
          (maxBranchLength / (thisBranchLength + 1) / totalLength) * 100
      )

      //branch backwards
    } else if (
      previousScreen &&
      previousScreen.branchSettings.branchQuestion &&
      !forwards
    ) {
      const thisBranchLength = screensData.filter(
        question =>
          question.branchSettings.branchQuestion &&
          question.branchSettings.branchQuestion.title ===
            previousScreen.branchSettings.branchQuestion.title
      ).length

      setCurrentPosition(
        currentPosition -
          (maxBranchLength / (thisBranchLength + 1) / totalLength) * 100
      )
      //normal forwards
    } else if (currentScreenIndex !== 0 && forwards) {

      setCurrentPosition(currentPosition + (1 / totalLength) * 100)
    }
    //normal backwards
    else if (currentPosition !== 0) {

      setCurrentPosition(currentPosition - (1 / totalLength) * 100)
    }

  }, [currentScreenIndex])

  return (
    <ProgressBarContainer currentPosition={Math.ceil(currentPosition)}>
      <Marker
        currentPosition={Math.ceil(currentPosition)}
        label="My fine"
        position={0}
      />
      <Marker
        currentPosition={Math.ceil(currentPosition)}
        label="My details"
        position={detailsPosition}
      />
      <Marker
        currentPosition={Math.ceil(currentPosition)}
        label="My options"
        position={100}
      />
    </ProgressBarContainer>
  )
}

export default ProgressBar
