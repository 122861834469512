import styled from "styled-components"
import { device } from "breakpoints"

export const ProgressBarContainer = styled.div`
  width: 400px;
  height: 2px;
  background: black;
  display: none;
  position: relative;
  @media ${device.tablet} {
    display: flex;
    margin-right: 40px;
    margin-bottom:-8px;
  }

  @media ${device.large} {
    margin-right: 20px;
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transition: 400ms;
    width: ${props => `${props.currentPosition}%`};
    height: 100%;
    background: #8abee7;
    max-width: 100%;
  }

  .buttons {
    transform: translateY(5px);

    button {
      font-size: 10px;
      margin-right: 10px;
    }
  }
`

export const Label = styled.span`
  position: absolute;
  white-space: nowrap;
  top: -30px;
  font-size: 12px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  color: #767676;
  transition: 200ms;
`

export const ProgressMarker = styled.div`
  height: 10px;
  width: 10px;
  background: #cac7c7;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: ${props => `${props.position}%`};
  z-index: 2;
  transition: 200ms;

  ${props =>
    props.active &&
    `
    background:#8ABEE7;

    ${Label} {
      color:#111;
    }
  `};
`
